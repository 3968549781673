import React from "react";
import Navbar from "components/Navbar/navbar";
import Intro3It from "components/Intro3It/intro3It";
import Footer from "components/Footer/footer";
import Services2It from "components/Services2It/services2It";
import DarkTheme from "layouts/Dark";
import staff from "data/staff.json";

const Homepage3 = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <Navbar nr={navbarRef} lr={logoRef} />
      <Intro3It 
        title="Staff Augmentation & Training Acceleration"
        img="/img/slid/staff.jpg"
        imgAlt="Staff Augmentation"
        desc="Harness the technological prowess of Elfonze Technologies for your staffing requirements, where seasoned tech experts seamlessly blend expertise with innovation.
        "
        links={staff.title} 
      />
      <Services2It style="4item" contentdata={staff.enterprise} />
      <Footer hideBGCOLOR />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Elfonze Technologies - Staff Augmentation & Training Acceleration</title>
      <meta key="description" name="description" 
      content="Harness the technological prowess of Elfonze for your staffing requirements, where seasoned tech experts seamlessly blend expertise with innovation." />
    </>
  )
}

export default Homepage3;
