import React from "react";
import Navbar from "components/Navbar/navbar";
import Intro3It from "components/Intro3It/intro3It";
import Footer from "components/Footer/footer";
import Services2It from "components/Services2It/services2It";
import DarkTheme from "layouts/Dark";
import esdm from "data/esdm.json";
import CallToActionesdm from "components/Call-to-actionesdm/call-to-actionesdm";

import EsdmProducts from "components/esdm/EsdmProducts";
import EsdmFeatures from "components/esdm/EsdmFeatures";
import EsdmServices from "components/esdm/EsdmServices";
import FullTestimonials from "components/Full-testimonials/full-testimonials";
import EsdmTrainings from "components/esdm/EsdmTrainings";
import CustomContactForm from "components/Contact-form/custom-contact-form";

const config ={
  "templateId":"template_elf_sixth_sense",
  "formType":"corporate",
  "emailMsg":"Lead For ESDM",
  "options":["Products","Domain Expertise","Learning Programs & Certifications"],
}

const Homepage3 = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  const scrollToContactForm = () => {
    document.getElementById('contact-elfonze-engineering').scrollIntoView();
  }

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <Navbar nr={navbarRef} lr={logoRef} />
      <Intro3It 
        title="Elfonze Engineering!"
        img="/img/slid/esdm.jpg"
        imgAlt="Elfonze Engineering"
        desc={<>Elfonze Engineering Products and Services excels in Electronics Systems Design and Manufacturing (ESDM), offering comprehensive solutions in embedded technology and semiconductors. <br/>Our expertise spans Automotive, Aerospace & Defence, and Industrial Engineering, delivering product engineering solutions such as advanced testing, certification, functional safety, ADAS, EV solutions, industrial automation, Industry 4.0, and cybersecurity for a connected future. 
        </>}
        links={esdm.title} 
      />
      {/* <Services2It style="4item" contentdata={esdm.enterprise} /> */}
      {/* <CallToActionesdm /> */}

      <div className="container">
      <Services2It style="4item" contentdata={esdm.enterprise} />
      <EsdmFeatures/> 
      <EsdmProducts/>
      {/* <EsdmServices/> */}
      {/* <FullTestimonials/> */}
      <EsdmTrainings scrollToContactForm={scrollToContactForm}/>
      <CustomContactForm config={config}/>
      </div>

      <Footer hideBGCOLOR />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Elfonze Technologies - Engineering</title>
      <meta key="description" name="description" 
      content="Elfonze's proficiency in Electronics Systems Design and Manufacturing (ESDM) positions us as leaders in IoT, Automotive, Telecommunications and beyond." />
   
    </>
  )
}

export default Homepage3;
