import React from "react";
// import blog3Data from "data/blog3.json";
import DarkTheme from "layouts/Dark";
import Navbar from "components/Navbar/navbar";

import PageHeader from "components/Page-header/page-header";
import Footer from "components/Footer/footer";
import CustomContactForm from "components/Contact-form/custom-contact-form";

const config ={
  "templateId":"template_elf_sixth_sense",
  "downloadUrl":"https://elfonzetechnologies1-my.sharepoint.com/:b:/g/personal/aatman_pb_elfonze_com/EQ-3Zn9MYBJOkCfz_BUMly8B9ea_Se3XqEQcUFpkS-1iyA?e=0wPWN5",
  "formType":"corporate",
  "emailMsg":"Lead for Digital Studio",
  "fileDownloadMsg":<span>To Know More, You Can Download The Deck Below.<br/> We will Contact With You Shortly</span>
}

const BlogDetailsDark = () => {

  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
      <Navbar nr={navbarRef} lr={logoRef} />
      <PageHeader
        title={<span>Elevate Your Brand: <br />From Eye-Catching Designs to Game-Changing Marketing.</span>}
        paragraph="Your Success is Our Art."
      />
       <section className="blog-pg single section-padding pt-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-11">
                <div className="post">
                  <div className="img">
                    <img src="/img/blogs/Blog_10/DM-05.jpg" alt="Your Vision, Our Craft: Design, Develop and Market for Digital Success" loading="lazy"/>
                  </div>
                  <div className="content pt-60">
                    <div className="row justify-content-center">
                      <div className="col-lg-10">
                        <div className="cont blog_h2">
                          
                          {/* <h2> Your Success is Our Art.</h2>
                           */}
                          <p>
                            Welcome to our design studio, where creativity and innovation come together to transform your ideas into digital masterpieces. We offer a comprehensive suite of services including graphic design, UI/UX design, web app creation and digital marketing. Our dedicated team excels in crafting visually captivating designs and intuitive user experiences, developing robust and scalable web applications and implementing strategic marketing campaigns that drive engagement and growth. Whether you're aiming to revamp your brand's digital presence or launch a groundbreaking project, our expertise ensures your vision becomes a powerful and engaging digital reality. Partner with us to elevate your brand and achieve exceptional results in the digital world.
                          </p>

                          <div className="row justify-content-center">
                            <div className="col-md-6">
                              <div className="mb-10">
                                <img src="/img/blogs/Blog_10/DM-01.jpg" alt="Your Vision, Our Craft: Design, Develop and Market for Digital Success" loading="lazy"/>
                              </div>
                            </div>
                          </div>                        

                          <p>
                            <strong className="text-white">UI/UX Design : </strong>

                            Our UI/UX design service is crafted to deliver exceptional user experiences. We blend creativity with functionality to create interfaces that captivate and engage users. From intuitive navigation to seamless interactions, every detail is meticulously designed to enhance usability. With user-centric design at the forefront, we ensure your digital products not only look stunning but also deliver unmatched satisfaction. Elevate your brand with our UI/UX expertise and leave a lasting impression on your audience.
                          </p>

                          <div className="row justify-content-center">
                            <div className="col-md-6">
                              <div className="mb-10">
                                <img src="/img/blogs/Blog_10/DM-02.jpg" alt="Your Vision, Our Craft: Design, Develop and Market for Digital Success" loading="lazy"/>
                              </div>
                            </div>
                          </div>

                          <p>
                            <strong className="text-white">Graphic Design : </strong>
                          
                            Bring your brand to life with visually stunning concepts tailored to your unique identity. From logos to marketing materials, we ensure every design element resonates with your audience. Our expert team combines creativity with strategy to deliver impactful visuals that leave a lasting impression. Whether you need a complete brand overhaul or a single design piece, we're dedicated to exceeding your expectations. Elevate your brand with our graphic design expertise and stand out in today's competitive landscape.
                          </p>

                          <div className="row justify-content-center">
                            <div className="col-md-6">
                              <div className="mb-10">
                                <img src="/img/blogs/Blog_10/DM-03.jpg" alt="Your Vision, Our Craft: Design, Develop and Market for Digital Success" loading="lazy"/>
                              </div>
                            </div>
                          </div>

                          <p>
                            <strong className="text-white">Web App Creation : </strong>

                            Embark on your digital journey with our app creation service, spanning iOS android and web platforms. Our experienced team crafts seamless and intuitive applications tailored to your specific needs. From brainstorming to deployment, we ensure your app is optimized for success across all platforms. Whether you're targeting mobile users or seeking a web-based solution, we're committed to delivering a polished and user-friendly experience. Partner with us to bring your app ideas to life and make an impact in the digital realm.
                          </p>

                          <div className="row justify-content-center">
                            <div className="col-md-6">
                              <div className="mb-10">
                                <img src="/img/blogs/Blog_10/DM-04.jpg" alt="Your Vision, Our Craft: Design, Develop and Market for Digital Success" loading="lazy"/>
                              </div>
                            </div>
                          </div>
                          
                          <p>
                            <strong className="text-white">Digital Marketing : </strong>

                            Elevate your online presence with our comprehensive digital marketing service. Our strategic approach encompasses SEO, social media, PPC and more to maximize your brand's visibility and engagement. We craft targeted campaigns designed to resonate with your audience and drive measurable results. From increasing website traffic to boosting conversions, we're committed to optimizing your digital footprint for success. Partner with us to unlock the full potential of your online marketing efforts and achieve your business objectives. 
                          </p>

                          <p>
                            In conclusion, our design studio stands as your ultimate partner in the digital realm, offering a holistic approach to elevate your brand to new heights. With a diverse array of services including graphic design, UI/UX design, web app creation and digital marketing, we provide a comprehensive solution to meet all your digital needs. From concept to execution, our dedicated team is committed to delivering excellence, ensuring that every aspect of your brand's digital presence is optimized for success. Let us be the catalyst for your digital transformation journey, where creativity meets innovation and together, we'll achieve remarkable results in the ever-evolving digital landscape.
                          </p>

                          <p>
                            For transformative design, development solutions and impactful digital strategies, contact us today.
                          </p>


                          <div className="line bottom right w-100"></div>
                    
                          <div className="share-info">
                            {/* <div className="social">
                              <a href="https://www.linkedin.com/company/elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-linkedin"></i>
                              </a>
                              <a href="https://www.instagram.com/elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-instagram"></i>
                              </a>
                              <a href="https://www.youtube.com/@ElfonzeTechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-youtube"></i>
                              </a>
                              <a href="https://www.facebook.com/Elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-facebook-f"></i>
                              </a>
                              <a href=" https://t.me/elfonze_technologies" target="_blank">
                                <i className="fab fa-telegram"></i>
                              </a>
                              <a href="https://www.quora.com/profile/Elfonze-Technologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-quora"></i>
                              </a>
                              <a href="https://twitter.com/Elfonze_Tech" target="_blank" rel="noreferrer">
                                <i className="fab fa-twitter"></i>
                              </a>
                            </div> */}
                            <div className="tags">
                              {/* <a href="#0">Web</a>,<a href="#0">Themeforest</a>,
                              <a href="#0">ThemesCamp</a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="container">
              {/* <ContactForm fullWidthFormVisibility={true}/> */}
              <CustomContactForm config={config}/>
          </div>

        </section>

      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Transforming Ideas into Digital Masterpieces: Design, Develop and Market with Precision. Your Success is Our Art.</title>
      <meta key="description" name="description" 
        content="We deliver specialized solutions for your growth. From stunning graphics and intuitive user experiences to powerful apps and effective marketing, we elevate your brand and drive success." />
    </>
  )
}

export default BlogDetailsDark;
