import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class FullTestimonialsElf extends React.Component {
  renderArrows = () => {
    return (
      <div className="arrows">
        <div className="container">
          <div
            onClick={() => this.slider.slickNext()}
            className="next cursor-pointer"
          >
            <span className="pe-7s-angle-right"></span>
          </div>
          <div
            onClick={() => this.slider.slickPrev()}
            className="prev cursor-pointer"
          >
            <span className="pe-7s-angle-left"></span>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const cont_2021 = [
      "Hands-on entrepreneurs driving technology to bridge vacuum around quality in IT Service and Consulting",
      "Charted out Elfonze delivery centers at Denver and Bangalore.",
      "125+ Oracle Enterprise Consulting talents carved out in the first year.",
    ];
    const cont_2022 = [
      "Bhubaneswar Delivery Centre Functional from Oct 2022.",
      "Launched ESDM in May 2022 Electronics System Design and Manufacturing Vertical",
    ];
    const cont_2023 = [
      "Partner with IESA as State Champion and CiG",
      "Bootcamp Partner on Embedded Engineering for NASSCOM",
      "Partner in Progress with KDEM, STPI",
      "Joint Business Relationship with PwC, Deloitte",
      "Pune delivery centre functional from Q3",
      "Hire, Train & Deploy for Twilio and more",
    ];

    const cont_2024 = [
      "Merger of iAMPS & Hz AI-EMC Design Synthetics BU",
      [
        "SynthEMC AI Platform inception",
        "Engineering Services & Consulting Launch",
      ],
      "Diversified Product Portfolio around",
      [
        "Oracle Mfg Ops Production Scheduling",
        "B2B Demand Supply App",
        "Unified Healthcare & Logistics Interface Platform",
      ],
      "Connected Supply Chain Services on O9, Zuora, Manhattan & Integrations",
      "MSP and Reseller for ",
      ["Rakuten Sixth Sense, FarEye"],
      "Hyderabad DC started April",
      "Sweden DC in July",
    ];



    return (
      <section
        className={`testimonials ${
          this.props.withIMG
            ? "section-padding bg-img"
            : this.props.withCOLOR
            ? "section-padding back-color"
            : this.props.noPadding ? ""
            : "section-padding"
        } ${this.props.classText ? this.props.classText : ""}`}
        style={{
          backgroundImage: `${
            this.props.withIMG ? "url(/img/testim-img.jpg)" : "none"
          }`,
        }}
      >
        {this.props.showHead && (
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-10">
                <div className="sec-head  text-center">
                  <h6 className="wow fadeIn" data-wow-delay=".5s">
                    Testimonials
                  </h6>
                  <h3 className="wow color-font">
                    We love our clients from all over the world.
                  </h3>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="container-fluid position-re">
          <div className="row wow fadeInUp" data-wow-delay=".5s">
            <div className="col-lg-12">
              <Slider
                className="slic-item "
                {...{
                  ref: (c) => (this.slider = c),
                  dots: false,
                  infinite: true,
                  arrows: true,
                  pauseOnHover: true,
                  pauseOnFocus: true,
                  autoplaySpeed:20000,
                  centerMode: true,
                  autoplay: true,
                  rows: 1,
                  slidesToScroll: 1,
                  slidesToShow: 3,
                  responsive: [
                    // {
                    //   breakpoint: 1024,
                    //   settings: {
                    //     slidesToShow: 1,
                    //     centerMode: true,
                    //   },
                    // },
                    {
                      breakpoint: 767,
                      settings: {
                        slidesToShow: 1,
                        centerMode: true,
                        pauseOnHover: true,
                        pauseOnFocus: true,
                      },
                    },
                    {
                      breakpoint: 480,
                      settings: {
                        slidesToShow: 1,
                        centerMode: true,
                        pauseOnHover: true,
                        pauseOnFocus: true,
                      },
                    },
                  ],
                }}
              >
                {/* <div className="item">
                  <div className="info valign">
                    <div className="cont">
                      <div className="author">
                        <img className="icon " src="/img/it.png"></img>
                        <h6 className="author-name color-font">
                        IT consulting & Turnkey Projects
                        </h6>
                        <span className="author-details">

                        </span>
                      </div>
                    </div>
                  </div>
                  <p>
                  Empowering businesses with cutting-edge technology solutions and strategic guidance.
                  </p>
                </div> */}
                <div className="item">
                  <div className="info valign">
                    <div className="cont">
                      <div className="author">
                        {/* <div className="img">
                          <img src="/img/clients/1.jpg" alt="" />
                        </div> */}
                        <h6 className="author-name ">2021</h6>
                        <span className="author-details color-font">
                          <b>INCEPTION & ORACLE FUSION CLOUD PRACTICE</b>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <ul
                      className="smp-list ab--left-align"
                      dangerouslySetInnerHTML={{
                        __html: `<li>${cont_2021.join("</li><li>")}</li>`,
                      }}
                    ></ul>

                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
                <div className="item">
                  <div className="info valign">
                    <div className="cont">
                      <div className="author">
                        {/* <div className="img">
                          <img src="/img/clients/1.jpg" alt="" />
                        </div> */}
                        <h6 className="author-name ">2022</h6>
                        <span className="author-details color-font">
                          <b>EMERGING CITIES & ESDM UNVEILED</b>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <ul
                      className="smp-list ab--left-align"
                      dangerouslySetInnerHTML={{
                        __html: `<li>${cont_2022.join("</li><li>")}</li>`,
                      }}
                    ></ul>

                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
                <div className="item">
                  <div className="info valign">
                    <div className="cont">
                      <div className="author">
                        {/* <div className="img">
                          <img src="/img/clients/1.jpg" alt="" />
                        </div> */}
                        <h6 className="author-name ">2023</h6>
                        <span className="author-details color-font">
                          <b>
                            {" "}
                            STRATEGIC <br /> PARTNERSHIPS
                          </b>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <ul
                      className="smp-list ab--left-align"
                      dangerouslySetInnerHTML={{
                        __html: `<li>${cont_2023.join("</li><li>")}</li>`,
                      }}
                    ></ul>
                  </div>
                </div>

                {/* subpointer */}
                <div className="item">
                  <div className="info valign">
                    <div className="cont">
                      <div className="author">
                        {/* <div className="img">
                          <img src="/img/clients/1.jpg" alt="" />
                        </div> */}
                        <h6 className="author-name ">2024</h6>
                        <span className="author-details color-font">
                          <b>M&A, PRODUCTS, NICHE DOMAIN EXAPNSION & MSP’s</b>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <ul className="smp-list ab--left-align">
                      {cont_2024.map((item, index) => {
                         if (Array.isArray(item)) {
                          return (

                              <ul className="custom_sublist" key={index}>
                                {item.map((subItem, subIndex) => (
                                  <li
                                    key={subIndex}
                                    dangerouslySetInnerHTML={{
                                      __html: subItem,
                                    }}
                                  />
                                ))}
                              </ul>

                          );
                        } else {
                          return (
                            <li
                              key={index}
                              dangerouslySetInnerHTML={{ __html: item }}
                            />
                          );
                        }
                      })}
                    </ul>

                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                </div>


                {/* <div className="item">
                  <div className="info valign">
                    <div className="cont">
                      <div className="author">

                        <h6 className="author-name ">
                        2024
                        </h6>
                        <span className="author-details color-font">


                        </span>
                      </div>
                    </div>
                  </div>
                  <p>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <ul className="smp-list ab--left-align" dangerouslySetInnerHTML={{ __html: `<li>${cont_2024.join('</li><li>')}</li>`}}></ul>


                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                  </p>
                </div> */}
                {/* <div className="item">
                  <div className="info valign">
                    <div className="cont">
                      <div className="author">

                        <h6 className="author-name color-font">
                        2025
                        </h6>
                        <span className="author-details">
                          AI

                        </span>
                      </div>
                    </div>
                  </div>
                  <p>
                        Coming soon...
                  </p>
                </div>
                <div className="item">
                  <div className="info valign">
                    <div className="cont">
                      <div className="author">

                        <h6 className="author-name color-font">
                        2026
                        </h6>
                        <span className="author-details">
                          AI

                        </span>
                      </div>
                    </div>
                  </div>
                  <p>
                        Coming soon...
                  </p>
                </div> */}
              </Slider>
            </div>
          </div>
          {this.renderArrows()}
        </div>
        <div className="container-fluid justify-content-center text-center">
          <div className="row">
            <div className="col-12">
              <h1 className="color-font ab--certified-member-title">
                Certified Member
              </h1>
              <div className="ab--certified-member-img-container">
                <img src="/img/certified-member.png" alt="Elfonze Certifications"></img>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default FullTestimonialsElf;