import React from "react";
import Navbar from "components/Navbar/navbar";
import Intro3It from "components/Intro3It/intro3It";
import Footer from "components/Footer/footer";
import Services2It from "components/Services2It/services2It";
import DarkTheme from "layouts/Dark";
import it from "data/it.json";

const Homepage3 = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <Navbar nr={navbarRef} lr={logoRef} />
      
      <Intro3It 
        title="IT Consulting & Turnkey Projects"
        img="/img/slid/it.jpg"
        imgAlt="IT Consulting"
        desc="Elfonze's IT consulting services are advisory services that helps with advanced enterprise and digital apps consulting, integration, data conversion and support"
        links={it.title} 
      />
      <Services2It style="4item" contentdata={it.enterprise} />

      <Services2It style="4item" contentdata={it.digital} />
      <Footer hideBGCOLOR />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Elfonze Technologies - IT Consulting & Turnkey Projects</title>
      <meta key="description" name="description" 
      content="Elfonze's IT consulting services are advisory services that helps with advanced enterprise and digital apps consulting, integration, data conversion and support" />
   
    </>
  )
}

export default Homepage3;
