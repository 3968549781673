import React, { useState } from "react";
// import blog3Data from "data/blog3.json";
import DarkTheme from "layouts/Dark";
import Navbar from "components/Navbar/navbar";
import CustomContactForm from "components/Contact-form/custom-contact-form";

import PageHeader from "components/Page-header/page-header";
import Footer from "components/Footer/footer";

import fileDownloadIcon from "../../../static/img/blogs/file_download.svg";


const config ={
  "templateId":"template_elf_sixth_sense",
  "downloadUrl":"https://elfonzetechnologies1-my.sharepoint.com/:b:/g/personal/k_shubham_elfonze_com/EaBZ27aieBhPhqKxscKzlvoBnRnuWJ3bMR2OYpm-PlnSqA?e=KwlV2F",
  "formType":"corporate",
  "emailMsg":"Lead For Oracle Lot Updates in Bulk using Visual Builders",
  "fileDownloadMsg":"You can download the pdf below"
}

const BlogDetailsDark = () => {

 
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);
  const [hideForm,setHideForm] = useState(false);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
      <Navbar nr={navbarRef} lr={logoRef} />
      <PageHeader
        title="Oracle Lot Updates in Bulk using VB"
        paragraph="Oracle Lot Updates using this approach enhances operational efficiency, reduces errors, and streamlines the process"
      />
       <section className="blog-pg single section-padding pt-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-11">
                <div className="post">
                  <div className="img">
                    <img src="/img/blogs/Blog_17/oracle-visual-builder_hero.jpg" alt="Oracle Lot Updates in Bulk using VB" />
                  </div>
                  <div className="content pt-60">
                    <div className="row justify-content-center">
                      <div className="col-lg-10">
                        <div className="cont blog_h2">
                          <p>
                              Using the <strong className="text-white">Visual Builder add-in for Excel</strong> in <strong className="text-white">Oracle Fusion</strong>, business users can efficiently update <strong className="text-white">Lot Expiration</strong> and <strong className="text-white">Origination Dates</strong> in bulk within their system. Here's a brief summary of how this feature works:
                          </p>
                          <h2>Key Benefits  : </h2>
                          <ul className="smp-list ab--left-align custom_sublist">
                            <li>
                              <p className="no-margin">
                                <strong>Bulk Updates : </strong>
                                Users can update large volumes of data at once, improving productivity and reducing manual errors compared to updating each record individually.
                              </p>
                            </li>
                            <li>
                              <p className="no-margin">
                                <strong>Integration with Oracle Fusion : </strong>
                                The Visual Builder add-in connects directly to Oracle Fusion applications, allowing for seamless updates to important fields like Lot Expiration and Origination Dates within the Oracle system.
                              </p>
                            </li>
                            <li>
                              <p className="no-margin">
                                <strong>Data Management : </strong>
                                Users can easily manipulate and manage data directly in Excel, using its familiar interface, while ensuring that changes are reflected in Oracle Fusion.
                              </p>
                            </li>
                            <li>
                              <p className="no-margin">
                                <strong>Automation : </strong>
                                By leveraging the capabilities of the add-in, the process of updating these dates becomes automated, saving time and minimizing human intervention.
                              </p>
                            </li>
                          </ul>
                          
                          <br/>
                          <h2>How it Works</h2>
                          <br/>

                          <ul className="smp-list ab--left-align custom_sublist custom-sublist-ordered" type="1">
                            <li>
                              <p className="no-margin">
                                <strong className="text-white">Connect Excel to Oracle Fusion</strong> via the Visual Builder add-in, which integrates directly with Oracle Fusion Cloud.
                              </p>
                            </li>
                            <li>
                              <p className="no-margin">
                                <strong className="text-white">Download Data : </strong>
                                Business users can pull the relevant data (such as lot numbers, expiration dates, and origination dates) into Excel.
                              </p>
                            </li>
                            <li>
                              <p className="no-margin">
                                <p>
                                    <strong className="text-white">Make Bulk Changes : </strong> Users can edit the data in Excel as needed. For example, adjusting expiration dates or updating origination dates for multiple records.</p>
                                </p>
                            </li>
                            <li>
                              <p className="no-margin">
                                <strong className="text-white">Upload Changes : </strong>
                                Once the bulk updates are made, the user can upload the modified data back into Oracle Fusion, ensuring the changes are reflected across the system.
                              </p>
                            </li>
                          </ul>
                          
                          <p>This feature is particularly useful for industries dealing with large volumes of inventory or products, as it helps ensure data consistency and accuracy while simplifying the updating process for users. It simplifies complex tasks, making them accessible to non-technical users, and provides flexibility and scalability for businesses of all sizes.</p>      
                          
                          <p>Ready to unlocking the full potential of oracle solutions? Dive into <a className="text-white" href="https://www.elfonze.com/blogs/maximizing-oracle-solutions/"><u>Elfonze Technologies Oracle Solutions</u></a> to discover tactics that top brands swear by</p>
                          <br/>
                          <br/>

                          <p className="d-inline">
                            Download the White Paper on Oracle Lot Expiration Date & Origination Date Update Using Visual Builder in Fusion below : 
                            <p  className="d-flex align-items-center">
                            <img src={fileDownloadIcon} alt="file_download_icon" className="blog_file_dnloadIcon"/>
                            <a className="text-white" href="#0" onClick={() => setHideForm((prev) => !prev)}><u>White Paper Oracle Lot Expiration Date & Origination Date Update Using Visual Builder in Fusion.pdf </u></a>
                            </p>
                          </p>

                          {
                            hideForm && 
                            <CustomContactForm config={config} />
                          }

                          <div className="share-info">
                            <div className="tags">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Oracle Lot Updates in Bulk using Visual Builder: A How-To Guide</title>
      <meta key="description" name="description" 
        content="Learn to streamline Oracle Lot Expiration & Origination Date updates with Excel’s Visual Builder add-in. Save time, boost accuracy, and reduce manual errors" />
    </>
  )
}

export default BlogDetailsDark;
