import React from "react";
import { Link } from "gatsby";

const BlogStanderd = ({ blogs }) => {
  return (
    <section className="blog-pg section-padding pt-0">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="posts">
              {
                blogs.map((blogItem, index) => (
                  <div className={`item ${index === blogs.length - 1 ? "" : "mb-80"}`} key={blogItem.id}>
                    <div className="img">
                      
                        <img src={blogItem.image} alt={blogItem.image_alt} loading="lazy"/>

                    </div>
                    <div className="content">
                      <div className="row justify-content-center">
                        <div className="col-10">
                          <Link to={blogItem.path} className="date">
                            <span className="num">{blogItem.date.day}{"-"}</span>
                            <span>{blogItem.date.month} </span>{"-"}<span>{blogItem.date.year}</span>
                          </Link>
                          <div className="tags">
                            {
                              blogItem.tags.map((tag, index) => (
                                <Link key={index} to={blogItem.path}>
                                  {tag}
                                </Link>
                              ))
                            }
                            <Link key={++index} to={blogItem.path}>
                               By
                            </Link>
                            <Link key={++index} to={blogItem.autherlinkedIn} target="_blank">
                            <i className="fab fa-linkedin"></i> {blogItem.authername} 
                            </Link>
                          </div>
                          <h4 className="title">
                            <Link to={blogItem.path}>
                              {blogItem.title}
                            </Link>
                          </h4>
                          <p>{blogItem.content}</p>
                          <Link to={blogItem.path} className="butn bord curve mt-30">
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
              {/* <div className="pagination">
                <span className="active">
                  <Link to={`/blog/blog-dark`}>1</Link>
                </span>
                <span>
                  <Link to={`/blog/blog-dark`}>2</Link>
                </span>
                <span>
                  <Link to={`/blog/blog-dark`}>
                    <i className="fas fa-angle-right"></i>
                  </Link>
                </span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogStanderd;
