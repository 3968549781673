import React from "react";
import verticalsdata from "data/showcase-slider-services.json";
import { Link } from "gatsby";

function Verticals() {
  return (
    <>
      <div className="sec-head text-center mt-80">
        <h4 className="wow fadeIn color-font ustom_services_heade">
          Our Services
        </h4>
      </div>

      <div className="services">
        <div className="container">
          <div className="row justify-content-center">
            {verticalsdata.map((vertical) => {
              return (
                <div className="items graphic wow fadeInUp col-lg-4 col-md-10">
                  <Link to={vertical.link}>
                    <div className="item-box text-center md-mb50 custom_box">
                      <img
                        className="icon custom_icon"
                        src={vertical.imageLink}
                        alt={vertical.imageAlt}
                        srcSet=""
                      />
                      <header className="color-font custom-header">
                        {vertical.heading}
                      </header>
                      <p>{vertical.description}</p>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Verticals;
