import React from "react";
// import blog3Data from "data/blog3.json";
import DarkTheme from "layouts/Dark";
import Navbar from "components/Navbar/navbar";

import PageHeader from "components/Page-header/page-header";
import Footer from "components/Footer/footer";

const BlogDetailsDark = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
      <Navbar nr={navbarRef} lr={logoRef} />
      <PageHeader
        className="insider-post"
        title="Elfonze Expands New Office Space"
        paragraph="In Bengaluru’s Upmarket CBD!"
      />
      <section className="blog-pg single section-padding pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-11">
              <div className="post">
                <div className="img">
                  <img
                    src="/img/elf_insider/elfonze-office-exp-ribboncut.jpeg"
                    alt="Elfonze Expands New Office Space in Bengaluru’s Upmarket CBD!"
                  />
                </div>
                <div className="content pt-60">
                  <div className="row justify-content-center">
                    <div className="col-lg-10">
                      <div className="cont blog_h2">
                        <p>
                          In a spectacular celebration of the world's Festive
                          and Holiday Season, Elfonze Technologies' opened an
                          expanded brand-new, 100-seat, state-of-the-art office
                          space in the city's upscale business district. The new
                          office will serve as the Engineering and Digital
                          Technology Experience Centre. Mr. Sanjeev Kumar Gupta,
                          the Chief Executive Officer of the Karnataka Digital
                          Economy Mission, KDEM, Government of Karnataka, opened
                          and graced the event as the Chief Guest. The event's
                          high point was Mr. Gupta's spiritually oriented
                          speech, which connected faith to the Almighty's
                          ability to provide us with blessings. delighted by the
                          hue of the t-shirts that the entire Elfonze Staff was
                          wearing. "The new office exudes ambition and energy,"
                          he said. It is more than just a place to work; it is a
                          lively, upbeat center for creativity and
                          collaboration. Elfonze’ s growth is commendable due to
                          its dedication to nurturing talent and adopting
                          progressive technologies.
                        </p>

                        <p>
                          Our team's recent victory in the DH Cup 2024 Corporate
                          Football tournament added to the festivities. Mr. Manu
                          Rao, Senior Editor, Special Editions, Deccan Herald,
                          speaking praised the boys' outstanding on-field skills
                          and teamwork and emphasized the crucial role that
                          games and sports play in nurturing departmental
                          cooperation and team spirit.
                        </p>

                        <p>
                          The 300-person strong workforce across was motivated
                          to adopt advanced technologies and serve as Elfonze
                          ambassadors by introducing at least one idea, one
                          client, or business opportunity. <br/>Co-founder & Director
                          of Elfonze Technologies, Vinod Kumar stressed the
                          strategic value of extending the office at Lavelle
                          Road, as this move demonstrates our dedication to
                          improving employee and client engagement. "Your words
                          reinforce the value each of our employees brings to
                          the organization," said Vijai Velu, CEO of Elfonze,
                          who thanked the dignitaries for their inspirational
                          remarks.
                        </p>

                        <div className="share-info">
                          {/* <div className="social">
                              <a href="https://www.linkedin.com/company/elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-linkedin"></i>
                              </a>
                              <a href="https://www.instagram.com/elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-instagram"></i>
                              </a>
                              <a href="https://www.youtube.com/@ElfonzeTechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-youtube"></i>
                              </a>
                              <a href="https://www.facebook.com/Elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-facebook-f"></i>
                              </a>
                              <a href=" https://t.me/elfonze_technologies" target="_blank">
                                <i className="fab fa-telegram"></i>
                              </a>
                              <a href="https://www.quora.com/profile/Elfonze-Technologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-quora"></i>
                              </a>
                              <a href="https://twitter.com/Elfonze_Tech" target="_blank" rel="noreferrer">
                                <i className="fab fa-twitter"></i>
                              </a>
                            </div> */}
                          <div className="tags">
                            <a href="#0">#KDEM</a>,
                            <a href="#0">#Innovation</a>,
                            <a href="#0">#CBD</a>,
                            <a href="#0">#Engineering</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>
        Elfonze Expands New Office Space in Bengaluru’s Upmarket CBD!
      </title>
      <meta
        key="description"
        name="description"
        content="In a spectacular celebration of the world's Festive and Holiday Season, Elfonze Technologies' opened an expanded brand-new, 100-seat, state-of-the-art office space in the city's upscale business district."
      />
    </>
  );
};

export default BlogDetailsDark;
