import React, { useState } from "react";
// import blog3Data from "data/blog3.json";
import DarkTheme from "layouts/Dark";
import Navbar from "components/Navbar/navbar";

import PageHeader from "components/Page-header/page-header";
import Footer from "components/Footer/footer";
import fileDownloadIcon from '../../../static/img/blogs/file_download.svg';
import CustomContactForm from "components/Contact-form/custom-contact-form";

const config ={
  "templateId":"template_elf_sixth_sense",
  "downloadUrl":"https://elfonzetechnologies1-my.sharepoint.com/:b:/g/personal/k_shubham_elfonze_com/ESfiGH_Wu5BOpG2lRFIuZVgB1rRPVwAd116i4mRJ6zX5AA?e=eVqeCJ",
  "formType":"corporate",
  "emailMsg":"Lead For Kanban Planning Method in Oracle Inventory",
  "fileDownloadMsg":"You can download the pdf below."
}

const BlogDetailsDark = () => {

  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);
  const [hideForm,setHideForm] =useState(false);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
      <Navbar nr={navbarRef} lr={logoRef} />
      <PageHeader
        title="Kanban Planning Method in Oracle Inventory"
        paragraph="Simple approach to reduce inventory, shorten lead times, and eliminate stockouts with minimal manual supervision"
      />
       <section className="blog-pg single section-padding pt-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-11">
                <div className="post">
                  <div className="img">
                    <img src="/img/blogs/Blog_16/kanban_hero.jpg" alt="Kanban Planning Method in Oracle Inventory" />
                  </div>
                  <div className="content pt-60">
                    <div className="row justify-content-center">
                      <div className="col-lg-10">
                        <div className="cont blog_h2">
                          <p>
                            There are various aspects which can be considered while deciding the particular planning method for any item in the inventory. All the planning methods provided by oracle functionality may not be applicable for all the items existing in the inventory. For example, some methods like Min-Max Planning would work ideally for certain items which are very critical from the manufacturing process point of view. Such items mandate certain minimum stock availability in the inventory at any given point in time in order to avoid the bottlenecks in the continuous process flows followed in the shop floor manufacturing or assembling the finished goods to meet the customer requirements on time. The Min-Max planning method would always ensure the minimum stock availability of these items in the inventory facilitating the smooth operations in the organization. Similarly, the other methods like Kanban Planning would be the best fit for certain items which are high cost but have relatively shorter lead times. Such items can be replenished quickly as and when they are needed rather than stocking them in the inventory. Thus, the inventory levels would always be at lower values with reference to such high-cost items which in turn will provide better working capital solutions for the organization.
                          </p>
                          <p className="d-inline">
                            If you’re curious about related strategies, don’t miss our latest article on <a href="https://www.elfonze.com/blogs/maximizing-oracle-solutions/" className="text-white d-inline"><u>Maximizing Oracle Solutions with Elfonze Technologies!</u></a>
                          </p>
                          <p>
                            Kanban planning can be used for the replenishment of components, processed parts, subassemblies and even final assemblies. Kanban is a pull replenishment system which aims at reduced inventory levels, shorter lead times, and minimal manual supervision. Unlike the push replenishment methods such as MRP (Material Requirement Planning) which suggests the users to release new orders for the items planned under these planning methods, kanban planning pulls the materials from its source when it needs them, signaling with a replenishment signal.
                          </p>
                          <p>
                            Kanbans can be replenished from an external supplier or an internal organization. Depending upon the source of replenishment for the items, the source type can be set in the pull sequence setup for the items. Oracle provides four types of sources form which the items planned under kanban planning can be replenished. Each source type will have a different workflow, or the activities associated with it and accordingly the data flow happens through different tables in oracle.
                          </p>
                          <p>
                            Kanbans are generally replenishable and cycle through the system from full to empty to full eventually, remaining active until they are withdrawn. One-time signals called non-replenishable kanbans are used primarily to manage sudden spikes in demand.
                          </p>
                          <p>
                          The system provides support for external devices such as bar code readers to read kanban cards and trigger a replenishment signal.
                          The inventory levels can be maintained at lower values with reference to high-cost & low-lead time items which in turn will provide better working capital solutions for the organization.
                          </p>
                         <p className="d-inline">
                          Looking for further reading? Our in-depth piece on <a className="text-white d-inline" href="https://www.elfonze.com/blogs/navigating-the-future-of-logistics/"><u>"Connected Supply Chains Navigating the Future of Logistics in a Digital World"</u></a> covers many of the challenges you might face
                         </p>
                         <br/>
                         <br/>
                         <br/>

                         <p className="d-inline">
                         Download the White Paper Kanban Planning in Oracle EBS below : 
                          <p className="d-flex align-items-center">
                          <img src={fileDownloadIcon} alt="file_download_icon" className="blog_file_dnloadIcon d-inline"/>  
                            <a className="text-white d-inline" href="#0" onClick={() => setHideForm((prev) => !prev)}><u>White Paper_Kanban Planning in Oracle EBS.pdf</u></a>
                          </p>
                         </p>

                         {
                          hideForm && 
                          <CustomContactForm config={config} />
                         }
                         <div className="share-info"></div>
                          
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Kanban Planning Oracle Inventory</title>
      <meta key="description" title="Kanban Planning Method in Oracle Inventory: Reduce Inventory & Eliminate Stockouts" name="description" 
        content="Discover how Kanban Planning in Oracle Inventory optimizes stock levels, shortens lead times, and cuts costs with minimal supervision. Learn practical steps to improve your manufacturing efficiency." />
    </>
  )
}

export default BlogDetailsDark;
