import React from "react";
// import blog3Data from "data/blog3.json";
import DarkTheme from "layouts/Dark";
import Navbar from "components/Navbar/navbar";

import PageHeader from "components/Page-header/page-header";
import Footer from "components/Footer/footer";


const BlogDetailsDark = () => {

 
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
      <Navbar nr={navbarRef} lr={logoRef} />
      <PageHeader
        title="Revolutionizing Supply Chains!"
        paragraph="Exploring innovative technologies Blue Yonder and O9"
      />
       <section className="blog-pg single section-padding pt-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-11">
                <div className="post">
                  <div className="img">
                    <img src="/img/blogs/Blog_6/F2.webp" alt="Blue_Yonder" />
                  </div>
                  <div className="content pt-60">
                    <div className="row justify-content-center">
                      <div className="col-lg-10">
                        <div className="cont blog_h2">
                          <h1 className="d-none">
                          Revolutionizing Supply Chains! 
                          </h1>
                          <h2>
                            - Blue Yonder 
                          </h2>
                          <p>
                            a leading provider of supply chain and retail solutions, offers a suite of tools designed to optimize operations and drive business success. From demand planning to warehouse management, Blue Yonder's AI-powered platform provides real-time insights and predictive analytics, enabling companies to make data-driven decisions and respond quickly to changing market conditions. By leveraging Blue Yonder's advanced capabilities, businesses can streamline their supply chain processes, reduce costs, and improve overall efficiency.
                          </p>
                          <h2>- O9 Solutions  </h2>
                          <p>
                            is another key player in the supply chain space, offering integrated planning and optimization solutions that empower companies to make smarter decisions and drive better outcomes. With its advanced algorithms and scenario planning capabilities, O9 enables businesses to optimize inventory levels, minimize stockouts, and maximize profitability. By harnessing the power of O9, companies can achieve greater visibility and control over their supply chain operations, leading to improved performance and customer satisfaction.
                          </p>
                          <p>
                            While Blue Yonder and O9 offer powerful solutions on their own, maximizing their potential requires expertise and experience. That's where Elfonze Technologies comes in. As a trusted partner with deep industry knowledge and technical proficiency, Elfonze helps businesses implement and optimize Blue Yonder and O9 solutions to achieve maximum results. Whether it's integrating disparate systems, customizing workflows, or providing ongoing support and maintenance, Elfonze ensures that companies can leverage the full capabilities of these transformative technologies and stay ahead of the competition.
                          </p>
                          <h2>- Embracing the Future of Logistics  </h2>
                          <p>
                            In today's hyperconnected world, supply chain optimization is more critical than ever. Companies that fail to adapt to changing market dynamics risk falling behind their competitors. By embracing innovative solutions like Blue Yonder and O9, businesses can revolutionize their supply chains, drive efficiency, and unlock new opportunities for growth. Partnering with Elfonze Technologies ensures that companies can navigate the complexities of modern logistics with confidence, staying ahead of the curve and achieving sustainable success in the digital age.
                          </p>
                          <p>
                            The future of logistics belongs to those who embrace innovation and adaptability. With Blue Yonder and O9 leading the charge in supply chain optimization, businesses have the tools they need to revolutionize their operations and stay ahead of the competition. 
                          </p>
                          <p>
                              Partnering with Elfonze Technologies ensures that companies can unlock the full potential of these transformative technologies, driving efficiency, and achieving sustainable growth in the ever-changing world of logistics.
                          </p>
                          <div className="share-info"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Revolutionizing Supply Chain Management: with Blue Yonder, O9</title>
      <meta key="description" name="description" 
        content="-Explore the dynamic world of supply chain innovation with Blue Yonder and O9 Solutions. Stay ahead of industry trends by Partnering with Elfonze Technologies." />
    </>
  )
}

export default BlogDetailsDark;
