import React from "react";
import Navbar from "components/Navbar/navbar";
import Intro3It from "components/Intro3It/intro3It";
import Footer from "components/Footer/footer";
import Services2It from "components/Services2It/services2It";
import DarkTheme from "layouts/Dark";
import design from "data/observability-product.json";

import CustomContactForm from "components/Contact-form/custom-contact-form";

const config ={
  "templateId":"template_elf_sixth_sense",
  "downloadUrl":"https://elfonzetechnologies1-my.sharepoint.com/:u:/g/personal/aatman_pb_elfonze_com/EWjNmGoU73VHssEXg6xtikEBgmLOEbaZG1ITbCmGXuMUFg?download=1",
  "formType":"corporate",
  "emailMsg":"Lead For Observability",
  "fileDownloadMsg":"You can download the data sheet below, fill it out and we'll get in touch with you shortly!"
}

const Homepage3 = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);





  return (
    <DarkTheme>
      <Navbar nr={navbarRef} lr={logoRef} />
      <Intro3It 
        title="Observability Product"
        img="/img/slid/observability.png"
        imgAlt="Observability Product"
        desc="In the current global market landscape, the need for digital solutions is growing, yet their intricacy is also increasing. The swift expansion of microservices, cloud infrastructures and varied applications results in massive data influxes, causing organizations to lack a holistic view of their operations. Consequently, businesses are grappling with expensive service interruptions and performance hindrances that adversely affect user experience and financial growth. This complexity underscores the urgent need for robust digital solutions that can offer comprehensive operational visibility and efficiency."
        links={design.title} 
      />

      <Services2It style="4item" contentdata={design.services} />
      
      {/* <CallToActiondesigndigital /> */}

      <CustomContactForm config={config} />

      <Footer hideBGCOLOR />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Elfonze Technologies - Observability Product</title>
      <meta key="description" name="description" 
      content="Digital Studio.
      Solutions makes a resounding impact to the operational efficiencies of organization and wellness of the organizations talents." />
   
    </>
  )
}

export default Homepage3;
